.adyen-checkout__payment-method {
  border-radius: 0.25rem !important;
  margin-top: 0.5rem !important;
  border: 1px solid #e6e1d7 !important;
  font-family: "Montserrat", sans-serif !important;

  &:first-child {
    margin-top: 0 !important;
  }

  &:first-child, :last-child {
    border-radius: 0.25rem !important;
  }
}

.adyen-checkout__payment-method--selected {
  background-color: #FFFAF5 !important;
  /* Payment method that has been selected */
}

.adyen-checkout__payment-method__header {
  /* Payment method icon and name */
}

.adyen-checkout__payment-method__radio {
  /* Radio button in payment method header */
}

.adyen-checkout__payment-method__radio--selected {
  /* Selected radio button in payment method header */
  background-color: #32C3FF !important;
}

.adyen-checkout__payment-method__name {
  font-family: "Montserrat", sans-serif !important;
  font-weight: normal !important;
  /* Payment method name in the payment method header */
}

.adyen-checkout__spinner__wrapper {
  /* Spinning icon */
}

.adyen-checkout__button {
  /* Buttons */
}

.adyen-checkout__button--pay {
  /* Pay button */
}

.adyen-checkout__field {
  /* Form field container */
}

.adyen-checkout__label {
  /* Form label container */
}

.adyen-checkout__label--focused {
  /* Form label container */
  & > .adyen-checkout__label__text {
    color: #32C3FF !important;
  }
}

.adyen-checkout__label__text {
  /* Text element inside the form label container */
}

.adyen-checkout__label__text--error {
  /* Text element inside the form label container */
  color: #FF0000 !important
}

.adyen-checkout__input {
  border: 1px solid #C3B9AA;

  &:active, &:focus, &:hover {
    border: 1px solid #32C3FF !important;
    box-shadow: 0 0 0 2px #32C3FF !important;
  }
}

.adyen-checkout__input--error {
  /* Error state for the input fields */
  border-color: #FF0000 !important
}

.adyen-checkout__input--valid {
  /* Error state for the input fields */
  border-bottom-color: #b9c4c9 !important;
}

.adyen-checkout__input--focus {
  /* Error state for the input fields */
  border: 1px solid #32C3FF !important;
  box-shadow: 0 0 0 2px #32C3FF !important;


  &:focus, &:active, &:hover {
    border: 1px solid #32C3FF !important;
  }

}

.adyen-checkout__error-text {
  /* Error message text */
  color: #FF0000 !important
}

.adyen-checkout__card__cardNumber__input {
  /* Input field for the card number */
}

.adyen-checkout__field--expiryDate {
  /* Input field for the expiry date */
}

.adyen-checkout__field__cvc {
  /* Input field for the CVC security code */
}

.adyen-checkout__card__holderName {
  /* Input field for cardholder name */
}

input.adyen-checkout-holderName {
  &:active, :active:hover {
    border: 1px solid #32C3FF !important;
    box-shadow: 0 0 0 2px #32C3FF !important;
  }
}

.adyen-checkout__checkbox__input {
  /* Checkboxes */
}

.adyen-checkout__checkbox__input:focus + .adyen-checkout__checkbox__label:after {
  border: 1px solid #32C3FF !important;
  box-shadow: 0 0 0 2px #32C3FF !important;
}

.adyen-checkout__checkbox__input:checked + .adyen-checkout__checkbox__label:after {
  background-color: #32C3FF !important;
  border: 1px solid #32C3FF !important;
}

.adyen-checkout__checkbox__label {
  /* Checkbox labels */
}

.adyen-checkout__radio_group__input {
  /* Radio buttons */
}

.adyen-checkout__dropdown__button {
  /* Dropdown button showing list of options */
}

.adyen-checkout__dropdown__list {
  /* Dropdown list */
}

.adyen-checkout__dropdown__element {
  /* Elements in the dropdown list */
}

.adyen-checkout__link {
  /* Links */
}