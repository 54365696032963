:root {
    --hero-image-overlap-mobile: 5rem;
    --hero-image-overlap-tablet: 3rem;
    --hero-image-overlap-desktop: 3.1rem;
}

@font-face {
    font-family: "Montserrat";
    src: url("/assetsV2/fonts/Montserrat-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("/assetsV2/fonts/Montserrat-Light.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("/assetsV2/fonts/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("/assetsV2/fonts/Montserrat-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    background: white;
    height: 100%;

    #launcher {
        display: none;
    }
}

* {
    box-sizing: border-box;
}

div#__next,
div#__next > div {
    height: 100%;
}
